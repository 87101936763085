<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="85">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Trade Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Trade: {{ transactionDescription? transactionDescription : '' }}</h2>
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
    </div>

    <div *ngIf="!errorMessage && transaction$ | async as transaction" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Client Name" [data]="transaction.clientName">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Account Number" [data]="transaction.accountNumber">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Broker Code" [data]="transaction.officeCode + transaction.salesCode">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="FCM" [data]="transaction.fcm">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Trade Date" [data]="transaction.tradeDate | date: 'shortDate'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Business Date" [data]="transaction.transactionDate | date: 'shortDate'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Last Trading Date" [data]="transaction.lastTradingDate | date: 'shortDate'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Option Expiration Date" [data]="transaction.optionExpirationDate">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Security Type" [data]="transaction.securityType">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Side" [data]="transaction.side | titlecase">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Open/Close" [data]="transaction.openCloseCode == 'O' ? 'Open' : '-' || transaction.openCloseCode == 'C'? 'Closed' : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Round Half Turn Code" [data]="transaction.roundHalfTurnCode">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Commission" [data]="transaction.commission == '0' ? '0' : transaction.commission ? transaction.commission.toFixed(2) : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Exchange Fee" [data]="transaction.exchangeFee == '0' ? '0' : transaction.exchangeFee ? transaction.exchangeFee.toFixed(2) : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="nfaFee" [data]="transaction.nfaFee == '0' ? '0' : transaction.nfaFee ? transaction.nfaFee.toFixed(2) : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Clearing Fee" [data]="(transaction.clearingFee == '0' ? '0' : transaction.clearingFee ? transaction.clearingFee.toFixed(2) : '-')">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Pit Fee" [data]="transaction.pitFee == '0' ? '0' : transaction.pitFee ? transaction.pitFee.toFixed(2) : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Brokerage Charge" [data]="transaction.brokerageCharge == '0' ? '0' : transaction.brokerageCharge ? transaction.brokerageCharge : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Gross Amount" [data]="transaction.grossAmount == '0' ? '0' : transaction.grossAmount ? transaction.grossAmount : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Net Amount" [data]="transaction.netAmount == '0' ? '0' : transaction.netAmount ? transaction.netAmount : '-'">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Quantity" [data]="transaction.quantity">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Price" [data]="transaction.price == '0' ? '0' : transaction.price ? transaction.price.toFixed(2) : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Closing Market/Settlement Price" [data]="transaction.closingMarketOrSettlementPrice == '0' ? '0' : transaction.closingMarketOrSettlementPrice ? transaction.closingMarketOrSettlementPrice.toFixed(2) : '-'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Market Value/Open Trade Equity" [data]="transaction.marketValueOrOpenTradeEquity == '0' ? '0' : transaction.marketValueOrOpenTradeEquity ? transaction.marketValueOrOpenTradeEquity : '-'">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="multiplication Factor" [data]="transaction.multiplicationFactor">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Exchange Id" [data]="transaction.exchangeId">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Contract Month Year" [data]="transaction.contractYearMonth | codeToMonth: 'long'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Spread Code" [data]="transaction.spreadCode">
        </ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">

        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Transaction Record Type" [data]="transaction.transactionRecordType">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Tracer Number" [data]="transaction.tracerNumber">
        </ati-labeled-data>
        <ati-labeled-data *ngIf="transaction.securityType == 'OPTION'" fxFlex.gt-xs="25" fxFlex.xs="100" label="Put/Call" [data]="transaction.securitySubType">
        </ati-labeled-data>
        <ati-labeled-data *ngIf="!transaction.securityType == 'FUTURE'" fxFlex.gt-xs="25" fxFlex.xs="100" label="Strike Price" [data]="transaction.strikePrice == '0' ? '0' : transaction.strikePrice ? transaction.strikePrice.toFixed(2) : '-'">
        </ati-labeled-data>
      </div>
    </div>
  </mat-card>
</div>
