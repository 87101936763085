
    <ng-container *ngIf="errorMessage$ | async"></ng-container>
    <div *ngIf="!errorMessage" fxLayout="column" fxFill>
      <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90">
        <mat-spinner diameter="60" color="accent"></mat-spinner>
        <p>Loading...</p>
      </div>
    </div>
    <div *ngIf="errorMessage" fxLayout="row" fxLayoutAlign="space-around start">
      <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="65">
        <div>
          <h2>Error Loading Golden Dragon</h2>
          <p [innerHTML]="errorMessage"></p>
          <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
            or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
        </div>
      </mat-card>
    </div>
  