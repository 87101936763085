<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="85">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Order Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Order: {{ orderDocId ? orderDocId : '' }}</h2>
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
    </div>

    <div *ngIf="!errorMessage && order$ | async as order" fxLayout="column" fxLayoutGap="15px">

      <!-- Client Order ID, Exchange Order ID, Status -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Client Order ID" [data]="order.clientOrderId">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Exchange Order ID" [data]="order.exchangeOrderId">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="34" fxFlex.xs="100" label="Status" [data]="order.status | replace | titlecase">
        </ati-labeled-data>
      </div>

      <!-- Account, Side, Contract, Spread Strategy-->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Account Number" [data]="order.accountNumber">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Side" [data]="order.side | titlecase">
        </ati-labeled-data>
        <ati-labeled-data *ngIf="order.contractYearMonth" fxFlex.gt-xs="34" fxFlex.xs="100" label="Contract"
          [data]="order.contractYearMonth | codeToMonth"></ati-labeled-data>
        <ati-labeled-data *ngIf="order.spreadStrategy" fxFlex.gt-xs="34" fxFlex.xs="100" label="Spread Strategy"
          [data]="order.spreadStrategy"></ati-labeled-data>
      </div>

      <!-- Security, Description -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Security" [data]="order.security"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="64" fxFlex.xs="100" label="Description"
          [data]="order.security | orderSecurity : commodityMap"></ati-labeled-data>
      </div>

      <!-- Quantity, Unfilled Quantity, and Price -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Quantity"
          [data]="getQuantityDisplay(order.quantity)"></ati-labeled-data>
        <ati-labeled-data
          *ngIf="order.status === 'NEW' || order.status === 'MODIFIED' || order.status === 'PARTIALLY_FILLED'"
          fxFlex.gt-xs="33" fxFlex.xs="100" label="Unfilled Quantity"
          [data]="getQuantityDisplay(order.unfilledQuantity)"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="34" fxFlex.xs="100" label="Price"
          [data]="order.type === 'MARKET' ? 'Market' : (getDisplayPrice(order.price, order.symbol, order) | number: '1.4-4')">
        </ati-labeled-data>
      </div>

      <!-- Type and Time in Force -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Type" [data]="order.type | replace | titlecase">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Time in Force"
          [data]="order.timeInForce !== 'DAY' ? order.timeInForce : order.timeInForce | titlecase"></ati-labeled-data>
        <ati-labeled-data *ngIf="order.type === 'STOP' || order.type === 'STOP_LIMIT'" fxFlex.gt-xs="34" fxFlex.xs="100"
          label="Stop Price" [data]="getDisplayPrice(order.stopPrice, order.symbol, order) | number: '1.4-4'">
        </ati-labeled-data>
      </div>

      <!-- Security Type and Subtype, Strike Price -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Security Type"
          [data]="(order.securityType === 'UDS' || order.securityType === 'OTC') ? order.securityType : order.securityType | replace | titlecase">
        </ati-labeled-data>
        <ati-labeled-data *ngIf="order.securitySubType" fxFlex.gt-xs="33" fxFlex.xs="100" label="Security Subtype"
          [data]="order.securitySubType | titlecase"></ati-labeled-data>
        <ati-labeled-data *ngIf="order.securityType === 'OPTION'" fxFlex.gt-xs="34" fxFlex.xs="100" label="Strike Price"
          [data]="getDisplayPrice(order.strikePrice, order.symbol, order) | number: '1.4-4'"></ati-labeled-data>
      </div>

      <!-- Creation, Expiration (and Cancellation) Dates, Completely Filled Timestamp -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Creation Date"
          [data]="order.creationTimestamp | date: 'M/d/yyyy, h:mm:ss a'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Expiration Date"
          [data]="order.expirationDate | date: 'M/d/yyyy'"></ati-labeled-data>
        <ati-labeled-data *ngIf="order.cancelledTimestamp" fxFlex.gt-xs="34" fxFlex.xs="100" label="Cancelled Date"
          [data]="order.cancelledTimestamp | date: 'M/d/yyyy, h:mm:ss a'"></ati-labeled-data>
        <ati-labeled-data *ngIf="order.completelyFilledTimestamp" fxFlex.gt-xs="34" fxFlex.xs="100"
          label="Completely Filled" [data]="order.completelyFilledTimestamp | date: 'M/d/yyyy, h:mm:ss a'">
        </ati-labeled-data>
      </div>

      <!-- Memo (optional) -->
      <div *ngIf="order.memo" fxFlex="100">
        <ati-labeled-data fxFlex.gt-xs="100" fxFlex.xs="100" label="Memo" [data]="order.memo"></ati-labeled-data>
      </div>

      <!--
        Fill Price or Split Fill Prices for partial fills
        (For partial fills that all fill at the same price - only display a single fill price)
      -->
      <h3 fxFlex="100" *ngIf="fillDataSource.data.length">Fills</h3>
      <mat-table *ngIf="fillDataSource.data.length" [dataSource]="fillDataSource" class="mat-elevation-z0" fxFlex>
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef fxFlex.xs="hasLeg ? '24' : '50'" fxFlex.gt-xs="hasLeg ? '25' : '50'">Qty
          </mat-header-cell>
          <mat-cell *matCellDef="let fill" fxFlex.xs="hasLeg ? '24' : '50'" fxFlex.gt-xs="hasLeg ? '25' : '50'">
            {{ fill.quantity }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderFillPrice">
          <mat-header-cell *matHeaderCellDef fxFlex.xs="hasLeg ? '25' : '50'" fxFlex.gt-xs="hasLeg ? '25' : '50'">Price
          </mat-header-cell>
          <mat-cell *matCellDef="let fill" fxFlex.xs="hasLeg ? '25' : '50'" fxFlex.gt-xs="hasLeg ? '25' : '50'">
            {{ getDisplayPrice(fill.orderFillPrice, fill.orderSymbol, order) | number: '1.4-4' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderBuyPrice">
          <mat-header-cell *matHeaderCellDef fxFlex.xs="25" fxFlex.gt-xs="25">Buy</mat-header-cell>
          <mat-cell *matCellDef="let fill" fxFlex.xs="25" fxFlex.gt-xs="25">
            {{ getDisplayPrice(fill.orderBuyPrice, fill.orderBuySymbol, order) | number: '1.4-4' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderSellPrice">
          <mat-header-cell *matHeaderCellDef fxFlex.xs="26" fxFlex.gt-xs="25">Sell</mat-header-cell>
          <mat-cell *matCellDef="let fill" fxFlex.xs="26" fxFlex.gt-xs="25">
            {{ getDisplayPrice(fill.orderSellPrice, fill.orderSellSymbol, order) | number: '1.4-4' }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="fillColumnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let fill; columns: fillColumnsToDisplay"></mat-row>
      </mat-table>

      <ng-container *ngIf="order.legs.length > 0">
        <h3 fxFlex="100">Legs</h3>
        <div *ngFor="let leg of order.legs; last as last" fxFlex="100">
          <arc-order-leg [leg]="leg" [commodityMap]="commodityMap"></arc-order-leg>
          <mat-divider inset *ngIf="!last"></mat-divider>
        </div>
      </ng-container>

    </div>
  </mat-card>
</div>
