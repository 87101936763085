import {
  ChangeDetectorRef,
  Component,
  OnInit, ViewChild,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked,
  ViewChildren,
  QueryList
} from '@angular/core';
import { Position, Commodity, SecuritySubType, Side } from '@advance-trading/ops-data-lib';
import { AccountService, PositionService, PositionSearchCriteria, OperationsDataService } from '@advance-trading/angular-ops-data';
import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { switchMap, map, startWith, filter, take } from 'rxjs/operators';
import { ObservableDataSource, ContractMonthUtility } from '@advance-trading/angular-common-services';
import { MatPaginator, MatSort, MatSnackBar, MatCheckbox } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { PositionSearchFormValidator } from './positions-form-validator';

const ALL_CLIENT_VIEWER_ROLE = 'AllClientViewer';

@Component({
  selector: 'arc-position-search',
  templateUrl: './position-search.component.html',
  styleUrls: ['./position-search.component.css']
})
export class PositionSearchComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  filteredBy = '';
  dataSource = new ObservableDataSource<Position>();
  selectedPositions$: Observable<Position[]>;
  isSearching = false;
  errorMessage: string;
  showData = false;
  private routeSubscription: Subscription;
  private commoditySubscription: Subscription;
  positionSides = Object.keys(Side);
  filteredCommodities: Observable<Commodity[]>;
  positionSubTypes = Object.keys(SecuritySubType);
  private commodities: Commodity[];
  public formValidator = new PositionSearchFormValidator();
  private chosenCommodity = '';
  private selectedTypes = [];
  private selectedSubTypes = [];

  tableState: { [key: string]: string | number } = {};
  private queryParams: Params;

  positionsForm: FormGroup = this.formBuilder.group({
    brokerCode: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.AccountsAndBrokerCodesValidator()),
    account: this.formBuilder.control({ value: '', disabled: false, }, this.formValidator.AccountsAndBrokerCodesValidator()),
    side: this.formBuilder.control({ value: this.positionSides, disabled: false, }),
    contractMonthYear: [''],
    minStrike: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    maxStrike: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    symbol: this.formBuilder.control({ value: '', disabled: false }),
    securityType: this.formBuilder.control({ value: '', disabled: false }),
    subTypeBoxes: [],
    securityBox: [],
  });

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('yearMonthPicker', { static: false }) yearMonthRef;
  @ViewChildren('subTypeBoxes') subTypeBoxes: QueryList<MatCheckbox>;
  @ViewChildren('securityBox') securityBox: QueryList<MatCheckbox>;

  constructor(
    private router: Router,
    private authzService: Auth0AuthzService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private positionService: PositionService,
    private operationsDataService: OperationsDataService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private monthCode: ContractMonthUtility,
  ) { }

  ngOnInit() {

    if (!this.authzService.currentUserHasRole(ALL_CLIENT_VIEWER_ROLE)) {
      this.isSearching = false;
      this.errorMessage = 'You do not have permission to view this information';
      return;
    }

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
      this.chosenCommodity = this.queryParams.symbol;
      this.positionsForm.get('account').setValue(this.queryParams.accounts);
      this.positionsForm.get('brokerCode').setValue(this.queryParams.brokerCodes);
      this.positionsForm.get('minStrike').setValue(this.queryParams.minStrike);
      this.positionsForm.get('maxStrike').setValue(this.queryParams.maxStrike);
      this.positionsForm.get('side').setValue(this.queryParams.side);

      if (this.queryParams.securitySubTypes) {
        this.selectedSubTypes = this.queryParams.securitySubTypes.split(',');
        this.positionsForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
      }
      if (this.queryParams.securityTypes) {
        if (this.queryParams.securityTypes.includes('FUTURE')) {
          this.selectedTypes = ['FUTURE'];
          this.positionsForm.get('securityBox').setValue(this.selectTypes);
        }
      }

      if (this.queryParams.contractMonthYear) {
        this.positionsForm.get('contractMonthYear')
          .setValue(this.monthCode.translateContractMonthToMoment(this.queryParams.contractMonthYear));
      }
      this.commoditySubscription = this.operationsDataService.getCommodityMap().subscribe(doc => {

        this.commodities = Object.values(doc.commodities);
        if (this.chosenCommodity) {
          const index = this.commodities.findIndex(val => val.id === this.chosenCommodity);
          this.positionsForm.get('symbol').setValue(this.commodities[index]);
          this.positionsForm.markAsDirty();
          this.searchPositions();
        }
        this.filteredCommodities = this.positionsForm.get('symbol').valueChanges.pipe(
          startWith<string | Commodity>(''),
          filter(value => typeof value === 'string'),
          map((commodityName: string) => {
            const filterValue = commodityName.toLowerCase();
            return this.commodities.filter(commodity => commodity.name.toLowerCase().includes(filterValue.toLowerCase()));
          })
        );
      });
      if (Object.keys(params).length && (!Object.keys(params).includes('symbol'))) {
        // Mark form as dirty so reset button appears
        this.positionsForm.markAsDirty();
        this.searchPositions();
      }
    }));

    // adjusted query parameter code if we decide to navigate to the screen that way.
    this.routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params['accountDocId']) {
        this.selectedPositions$ = this.positionService.getAllPositionsByAccountDocId(params['accountDocId']);
        this.showData = true;
      } else if (params['brokerCode']) {
        this.selectedPositions$ = this.positionService.findAllPositionsByBrokerCode(params['brokerCode']);
        this.showData = true;
      } else if (params['clientDocId']) {
        this.selectedPositions$ = this.positionService.findAllPositionsByClientDocId(params['clientDocId']);
        this.showData = true;
      }
    });
  }

  ngAfterViewInit() {
    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = this.selectedSubTypes.includes(subTypeBox.value));
    this.securityBox.forEach(typeBox => typeBox.checked = this.selectedTypes.includes(typeBox.value));
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.commoditySubscription) {
      this.commoditySubscription.unsubscribe();
    }
  }

  searchPositions(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue
      };
    }
    this.showData = false;
    this.changeDetector.detectChanges();

    this.selectedPositions$ = this.chooseQuery();
    this.showData = true;

  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  onSubTypeChange(event) {
    if (event.checked) {
      this.selectedSubTypes.push(event.source.value);
    } else {
      this.selectedSubTypes = this.selectedSubTypes.filter(type => type !== event.source.value);
    }
    this.positionsForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
    if (this.selectedSubTypes.length) {
      this.positionsForm.get('subTypeBoxes').markAsDirty();
    }
  }

  onTypeChange(event) {
    if (event.checked) {
      this.selectedTypes.push(event.source.value);
    } else {
      this.selectedTypes = this.selectedTypes.filter(type => type !== event.source.value);
    }
    this.positionsForm.get('securityBox').setValue(this.selectedTypes);
    if (this.selectedTypes.length) {
      this.positionsForm.get('securityBox').markAsDirty();
    }
  }

  handlePositionListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  selectTypes() {
    const returnTypes = [];
    if (this.selectedTypes.includes('FUTURE')) {
      returnTypes.push('FUTURE', 'FUTURE_SPREAD');
    }
    if (this.selectedSubTypes.includes('PUT') && this.selectedSubTypes.includes('CALL')) {
      returnTypes.push('OPTION');
    }
    if (!returnTypes.includes('FUTURE') && !returnTypes.includes('FUTURE_SPREAD')
      && !this.selectedSubTypes.includes('PUT') && !this.selectedSubTypes.includes('CALL')) {
      return null;
    }
    return returnTypes;
  }

  private chooseQuery(): Observable<Position[]> {
    const accountValue = this.positionsForm.get('account').value;
    const brokerCodeValue = this.positionsForm.get('brokerCode').value;
    const oneSide = this.positionsForm.get('side').value;
    const MonthYearValue = this.positionsForm.get('contractMonthYear').value;
    let yearMonth = '';
    let accountNumbers = [];
    let accountNumbersFiltered = [];
    let brokerCodes = [];
    let brokerCodesFiltered = [];
    const minStrike = parseFloat(this.positionsForm.get('minStrike').value);
    const maxStrike = parseFloat(this.positionsForm.get('maxStrike').value);
    const symbolValue = this.positionsForm.get('symbol').value;
    const securityTypes = this.selectTypes();

    this.queryParams = this.tableState as Params;

    if (MonthYearValue) {
      yearMonth = this.monthCode.translateMomentToContractMonth(MonthYearValue);
      this.queryParams.contractMonthYear = yearMonth;
    }

    if (accountValue) {
      accountNumbers = accountValue.split(',').map(accountNum => accountNum.trim());
      accountNumbersFiltered = Array.from(new Set(accountNumbers));
      this.queryParams.accounts = accountValue;

      this.filteredBy = `Account - ${accountNumbersFiltered.join(', ')}`;
    } else if (brokerCodeValue) {
      brokerCodes = brokerCodeValue.split(',').map(code => code.trim());
      brokerCodesFiltered = Array.from(new Set(brokerCodes));
      this.queryParams.brokerCodes = brokerCodeValue;

      this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    }

    if (oneSide) {
      this.queryParams.side = oneSide;
    }

    if (minStrike) {
      this.queryParams.minStrike = minStrike;
    }

    if (maxStrike) {
      this.queryParams.maxStrike = maxStrike;
    }

    if (symbolValue) {
      this.queryParams.symbol = symbolValue.id;
    }

    if (this.selectedSubTypes.length > 0) {
      this.queryParams.securitySubTypes = this.selectedSubTypes.join(',');
    }

    if (securityTypes && securityTypes.length > 0) {
      this.queryParams.securityTypes = securityTypes.join(',');
    }

    const subTypeSearch: PositionSearchCriteria = {
      contractYearMonth: yearMonth,
      minStrikePrice: minStrike,
      maxStrikePrice: maxStrike,
      commodityId: symbolValue.id,
      side: oneSide,
      securitySubType: this.selectedSubTypes[0],
    };

    const securityTypeSearch: PositionSearchCriteria = {
      contractYearMonth: yearMonth,
      minStrikePrice: minStrike,
      maxStrikePrice: maxStrike,
      commodityId: symbolValue.id,
      side: oneSide,
      securityType: securityTypes,
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });

    if (accountValue) {

      let subTypeQuery = of([]);
      if (this.selectedSubTypes.length === 1) {
        subTypeQuery = combineLatest(accountNumbersFiltered.map(accountNum => {
          return this.accountService.getAccountsByNumber(accountNum);
        })).pipe(
          map(accounts => accounts.flat())
        ).pipe(
          switchMap(accounts => {
            if (accounts.length === 0) {
              return of([]);
            }
            return combineLatest(accounts.map(account => {
              return this.positionService.getAllPositionsByAccountAndSearchParameters(account.docId, subTypeSearch);
            }));
          })).pipe(
            map(positions => positions.flat())
          );
      }

      let typeQuery = of([]);
      if (!(this.selectedTypes.length === 0 && this.selectedSubTypes.length === 1)) {
        typeQuery = combineLatest(accountNumbersFiltered.map(accountNum => {
          return this.accountService.getAccountsByNumber(accountNum);
        })).pipe(
          map(accounts => accounts.flat())
        ).pipe(
          switchMap(accounts => {
            if (accounts.length === 0) {
              return of([]);
            }
            return combineLatest(accounts.map(account => {
              return this.positionService.getAllPositionsByAccountAndSearchParameters(account.docId, securityTypeSearch);
            }));
          })).pipe(
            map(positions => positions.flat())
          );
      }
      return combineLatest([subTypeQuery, typeQuery]).pipe(map(positions => positions.flat()));
    }

    if (brokerCodeValue) {
      let subTypeQuery = of([]);
      if (this.selectedSubTypes.length === 1) {
        subTypeQuery = combineLatest(brokerCodesFiltered.map(code => {
          return this.accountService.getAccountsByBrokerCode(code);
        })).pipe(
          map(accounts => accounts.flat())
        ).pipe(
          switchMap(accounts => {
            if (accounts.length === 0) {
              return of([]);
            }
            return combineLatest(accounts.map(account => {
              return this.positionService.getAllPositionsByAccountAndSearchParameters(account.docId, subTypeSearch);
            }));
          })).pipe(
            map(positions => positions.flat())
          );
      }

      let typeQuery = of([]);
      if (!(this.selectedTypes.length === 0 && this.selectedSubTypes.length === 1)) {
        typeQuery = combineLatest(brokerCodesFiltered.map(code => {
          return this.accountService.getAccountsByBrokerCode(code);
        })).pipe(
          map(accounts => accounts.flat())
        ).pipe(
          switchMap(accounts => {
            if (accounts.length === 0) {
              return of([]);
            }
            return combineLatest(accounts.map(account => {
              return this.positionService.getAllPositionsByAccountAndSearchParameters(account.docId, securityTypeSearch);
            }));
          })).pipe(
            map(positions => positions.flat())
          );
      }
      return combineLatest([subTypeQuery, typeQuery]).pipe(map(positions => positions.flat()));
    }
  }

  // Clears the value and disables client field and enables account field
  accountFieldClicked() {
    this.positionsForm.get('brokerCode').setValue('');
    this.positionsForm.get('brokerCode').disable();
    this.positionsForm.get('account').enable();
  }

  // Clears the value and disables account field and enables client field
  clientFieldClicked() {
    this.positionsForm.get('account').setValue('');
    this.positionsForm.get('account').disable();
    this.positionsForm.get('brokerCode').enable();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  reset() {
    this.positionsForm.reset();
    this.positionsForm.get('side').setValue('');
    this.positionsForm.get('account').enable();
    this.positionsForm.get('brokerCode').enable();
    this.positionsForm.get('symbol').setValue('');
    this.chosenCommodity = '';
    this.selectedSubTypes = [];
    this.selectedTypes = [];
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = false);
    this.securityBox.forEach(box => box.checked = false);
    this.showData = false;
  }

  selectYearMonth(event: moment.Moment) {
    this.positionsForm.get('contractMonthYear').setValue(event);
    this.positionsForm.get('contractMonthYear').markAsDirty();
    this.yearMonthRef.close();
  }

  displayCommodity(commodity: Commodity): string {
    if (commodity) {
      return commodity.name;
    }
    return '';
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  clearSide() {
    this.positionsForm.get('side').setValue('');
  }

  handlePositionListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }
}
