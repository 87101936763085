// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    clientID: 'XcCN17YrLbfQ6mw8La9DJnii2QtKwn5p',
    domain: 'login.dev.advance-trading.com',
    namespace: 'https://advance-trading.com/',
    audience: 'https://api.advance-trading.com',
    redirectUri: 'https://arc.dev.advance-trading.com/callback',
    logoutUri: 'https://arc.dev.advance-trading.com',
    scope: 'openid profile email',
    accessTokenLifespanSeconds: 1800
  },
  firebase: {
    apiKey: 'AIzaSyCQq0htWwUyatXnRtEMXzvhtAVMGYrZuC0',
    authDomain: 'advance-trading-dev.firebaseapp.com',
    databaseURL: 'https://advance-trading-dev.firebaseio.com',
    projectId: 'advance-trading-dev',
    storageBucket: 'advance-trading-dev.appspot.com',
    messagingSenderId: '321771635913'
  },
  services: {
    httpFirebaseLogin: 'https://us-central1-advance-trading-dev.cloudfunctions.net/httpFirebaseLogin',
    httpAuth0Manage: 'https://us-central1-advance-trading-dev.cloudfunctions.net/httpAuth0Manage',
    httpUserUpdate: 'https://us-central1-advance-trading-dev.cloudfunctions.net/httpUserUpdate',
    httpClientSecureData: 'https://us-central1-advance-trading-dev.cloudfunctions.net/httpClientSecureData',
    httpRegistrationType: 'https://us-central1-advance-trading-dev.cloudfunctions.net/httpRegistrationType'
  },
  versionNumber: '0.1.0',
  versionDate: '02/19/20'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
