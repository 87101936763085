<div fxFlex="100">
    <div>
      <div *ngIf="!errorMessage" fxLayout="column">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="positions-filter-field" #filter>
          <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <button *ngIf="exportable && selectedPositions$ | async" aria-label="Export" id="export-positions" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
        <mat-menu #exportMenu="matMenu">
          <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Positions'))">Excel</button>
          <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Positions'))">CSV</button>
          <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Positions'))">JSON</button>
        </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

        <ng-container matColumnDef="salesCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="12.5">Sales Code</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="30" fxFlex.gt-xs="12.5">{{ position.officeCode + position.salesCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="accountNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="12.5">Account</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="30" fxFlex.gt-xs="12.5">{{ position.accountNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="client">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="20">Client</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="30" fxFlex.gt-xs="20">{{ position.clientName | titlecase }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="totalQuantity">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="20" fxFlex.gt-xs="10">Quantity</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="20" fxFlex.gt-xs="10">{{ position.side === "BUY" ? '+' : '-' }}{{ position.totalQuantity }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="contractYearMonth">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="12.5">Month/Year</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="30" fxFlex.gt-xs="12.5">{{ position.contractYearMonth | codeToMonth: 'long' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="strikePrice">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="50" fxFlex.gt-xs="10">Strike</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="50" fxFlex.gt-xs="10">{{ position.strikePrice ? position.strikePrice.toFixed(2) : '-' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="commodityId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="12.5">Commodity</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="30" fxFlex.gt-xs="12.5">{{ getCommodityDisplayName(position.commodityId) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="securitySubType">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="20" fxFlex.gt-xs="10">Put/Call</mat-header-cell>
          <mat-cell *matCellDef="let position" fxFlex.xs="20" fxFlex.gt-xs="10">{{ position.securitySubType | titlecase}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row  *matRowDef="let position; columns: columnsToDisplay" #tooltip="matTooltip" [matTooltip]="getTooltip(position)" matTooltipClass="segment-tooltip" (click)="selectPosition(position)"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>
    </div>
    </div>
</div>
