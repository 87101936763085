import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, AfterViewChecked, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar, MatCheckbox } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { take, switchMap, map, startWith, filter } from 'rxjs/operators';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import {
  Order,
  Account,
  Side,
  Commodity,
  SecurityType,
  SecuritySubType
} from '@advance-trading/ops-data-lib';

import {
  AccountService,
  OrderService,
  OrderSearchCriteria,
  OperationsDataService
} from '@advance-trading/angular-ops-data';
import { ContractMonthUtility } from '@advance-trading/angular-common-services';
import { OrderSearchFormValidator } from './order-search-form-validator';

const ALL_CLIENT_VIEWER_ROLE = 'AllClientViewer';
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();

@Component({
  selector: 'arc-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.css'],
})
export class OrderSearchComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @ViewChild('orderYearMonthPicker', { static: false }) orderYearMonthRef;

  @ViewChildren('subTypeBoxes') subTypeBoxes: QueryList<MatCheckbox>;
  @ViewChildren('securityBox') securityBox: QueryList<MatCheckbox>;

  public formValidator = new OrderSearchFormValidator();

  orderSearchForm: FormGroup = this.formBuilder.group({
    brokerCode: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.AccountsAndBrokerCodesValidator()),
    account: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.AccountsAndBrokerCodesValidator()),
    side: this.formBuilder.control({ value: '', disabled: false }),
    contractMonthYear: [''],
    strikePriceMin: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    strikePriceMax: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    symbol: this.formBuilder.control({ value: '', disabled: false }),
    securityType: this.formBuilder.control({ value: '', disabled: false }),
    subTypeBoxes: [],
    securityBox: [],
    startDate: this.formBuilder.control({ value: DEFAULT_START_DATE, disabled: false }),
    endDate: this.formBuilder.control({ value: DEFAULT_END_DATE, disabled: false }),
  });

  filteredBy = '';
  errorMessage: string;
  isSearching = false;
  showOrders = false;
  selectedOrders$: Observable<Order[]>;
  tableState: { [key: string]: string | number } = {};
  orderSides = Object.keys(Side);
  private queryParams: Params;
  filteredCommodities: Observable<Commodity[]>;
  commoditySubscription: Subscription;
  private commodities: Commodity[];
  orderSecurityTypes = Object.keys(SecurityType);
  orderSubTypes = Object.keys(SecuritySubType);
  private selectedSubTypes = [];
  private selectedTypes = [];
  private chosenCommodity = '';
  private hasUds = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar,
    private monthCode: ContractMonthUtility,
    private operationsDataService: OperationsDataService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(ALL_CLIENT_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search orders.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.queryParams = Object.assign({}, params);
      this.chosenCommodity = this.queryParams.symbol;
      this.orderSearchForm.get('account').setValue(this.queryParams.account);
      this.orderSearchForm.get('brokerCode').setValue(this.queryParams.brokerCode);
      if (this.queryParams.contractMonthYear) {
        this.orderSearchForm.get('contractMonthYear')
          .setValue(this.monthCode.translateContractMonthToMoment(this.queryParams.contractMonthYear));
      }
      this.orderSearchForm.get('startDate').setValue(this.queryParams.startDate);
      this.orderSearchForm.get('endDate').setValue(this.queryParams.endDate);
      this.orderSearchForm.get('side').setValue(this.queryParams.side);
      this.orderSearchForm.get('strikePriceMin').setValue(this.queryParams.strikePriceMin);
      this.orderSearchForm.get('strikePriceMax').setValue(this.queryParams.strikePriceMax);

      if (this.queryParams.securitySubTypes) {
        this.selectedSubTypes = this.queryParams.securitySubTypes.split(',');
        this.orderSearchForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
      }
      if (this.queryParams.securityTypes) {
        if (this.queryParams.securityTypes.includes('FUTURE')) {
          this.selectedTypes = ['FUTURE'];
          this.orderSearchForm.get('securityBox').setValue(this.selectTypes);
        }

      }
      if (Object.keys(params).length) {
        // Mark form as dirty so reset button appears
        this.orderSearchForm.markAsDirty();
        this.searchOrders();
      }
      this.commoditySubscription = this.operationsDataService.getCommodityMap().subscribe(doc => {

        this.commodities = Object.values(doc.commodities);
        if (this.chosenCommodity) {
          const index = this.commodities.findIndex(val => val.id === this.chosenCommodity);
          this.orderSearchForm.get('symbol').setValue(this.commodities[index]);
        }
        this.filteredCommodities = this.orderSearchForm.get('symbol').valueChanges.pipe(
          startWith<string | Commodity>(''),
          filter(value => typeof value === 'string'),
          map((commodityName: string) => {
            const filterValue = commodityName.toLowerCase();
            return this.commodities.filter(commodity => commodity.name.toLowerCase().includes(filterValue.toLowerCase()));
          })
        );
      });
    });
  }

  ngOnDestroy() {
    if (this.commoditySubscription) {
      this.commoditySubscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = this.selectedSubTypes.includes(subTypeBox.value));
    this.securityBox.forEach(typeBox => typeBox.checked = this.selectedTypes.includes(typeBox.value));
    this.changeDetector.detectChanges();
  }

  reset() {
    this.orderSearchForm.reset();
    this.clearQueryParams();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
    this.selectedSubTypes = [];
    this.selectedTypes = [];
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = false);
    this.securityBox.forEach(box => box.checked = false);
    this.orderSearchForm.get('side').setValue('');
    this.orderSearchForm.enable();
    this.orderSearchForm.get('symbol').setValue('');
    this.chosenCommodity = '';
    this.hasUds = false;
    this.orderSearchForm.markAsPristine();
    this.showOrders = false;
  }

  searchOrders(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue,
      };
    }

    this.showOrders = false;
    this.changeDetector.detectChanges();
    this.selectedOrders$ = this.chooseQuery();
    this.showOrders = true;
  }

  selectMonthYear(event: moment.Moment) {
    this.orderSearchForm.get('contractMonthYear').setValue(event);
    this.orderSearchForm.get('contractMonthYear').markAsDirty();
    this.orderYearMonthRef.close();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleOrderListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });
  }

  handleOrderListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  onSubTypeChange(event) {
    if (event.checked) {
      this.selectedSubTypes.push(event.source.value);
    } else {
      this.selectedSubTypes = this.selectedSubTypes.filter(type => type !== event.source.value);
    }
    this.orderSearchForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
    if (this.selectedSubTypes.length) {
      this.orderSearchForm.get('subTypeBoxes').markAsDirty();
    }
  }

  onTypeChange(event) {
    if (event.checked) {
      this.selectedTypes.push(event.source.value);
    } else {
      this.selectedTypes = this.selectedTypes.filter(type => type !== event.source.value);
    }
    this.orderSearchForm.get('securityBox').setValue(this.selectedTypes);
    if (this.selectedTypes.length) {
      this.orderSearchForm.get('securityBox').markAsDirty();
    }
  }

  private chooseQuery(): Observable<Order[]> {
    const brokerCodeValue = this.orderSearchForm.get('brokerCode').value;
    const accountValue = this.orderSearchForm.get('account').value;

    this.queryParams = this.tableState as Params;

    if (accountValue) {
      this.queryParams.account = accountValue;
    }

    if (brokerCodeValue) {
      this.queryParams.brokerCode = brokerCodeValue;
    }

    if (!brokerCodeValue && !accountValue) {
      this.openSnackBar('Please enter Account Number or Broker Code', 'DISMISS', false);
      return of([]);
    }

    // brokerCode search selected
    if (accountValue) {
      return this.retrieveOrdersByAccountNumber(accountValue);
    } else if (brokerCodeValue) {
      return this.retrieveOrdersByBrokerCode(brokerCodeValue);
    }
  }

  private retrieveOrdersByAccountNumber(accountValue: string): Observable<Order[]> {
    const accountNumbers = this.splitAccounts(accountValue);

    if (accountNumbers.length === 0) {
      return of([]);
    }
    // Retrieve all orders associated with accounts searched on
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        let acctNumber = accountNumber;
        if (accountNumber.length === 8) {
          acctNumber = accountNumber.substring(3);
        }
        return this.accountService.getAccountsByNumber(acctNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        return this.retrieveOrdersByAccountDocId(accounts);
      }));
  }

  private retrieveOrdersByBrokerCode(brokerCodeValue: string): Observable<Order[]> {
    const brokerCodesFiltered = this.splitBrokerCodes(brokerCodeValue);

    return combineLatest(
      // Use broker code to get broker relationship doc id
      brokerCodesFiltered.map((code) => {
        return this.accountService.getAccountsByBrokerCode(code);
      })
    ).pipe(map(accounts => accounts.flat()))
      .pipe(switchMap((accounts) => {
        return this.retrieveOrdersByAccountDocId(accounts);
      }));
  }

  private selectTypes() {
    const returnTypes = [];
    if (this.selectedTypes.includes('FUTURE')) {
      returnTypes.push('FUTURE', 'FUTURE_SPREAD');
    }
    if (this.selectedSubTypes.includes('PUT') || this.selectedSubTypes.includes('CALL')) {
      returnTypes.push('UDS');
      this.hasUds = true;
    }
    if (this.selectedSubTypes.includes('PUT') && this.selectedSubTypes.includes('CALL')) {
      returnTypes.push('OPTION');
    }
    if (!returnTypes.includes('FUTURE') && !returnTypes.includes('FUTURE_SPREAD')
      && !this.selectedSubTypes.includes('PUT') && !this.selectedSubTypes.includes('CALL') && !this.hasUds) {
      return null;
    }
    return returnTypes;
  }

  private retrieveOrdersByAccountDocId(accounts: Account[]): Observable<Order[]> {
    const sideValue = this.orderSearchForm.get('side').value;
    const symbolValue = this.orderSearchForm.get('symbol').value;
    const contractMonthYear = this.orderSearchForm.get('contractMonthYear').value;
    const hasSecuritySubType = this.selectedSubTypes.length === 1;
    const strikePriceMax = parseFloat(this.orderSearchForm.get('strikePriceMax').value);
    const strikePriceMin = parseFloat(this.orderSearchForm.get('strikePriceMin').value);
    let startDate = this.orderSearchForm.get('startDate').value;
    let endDate = this.orderSearchForm.get('endDate').value;
    const securityTypes = this.selectTypes();
    const hasAnySubType = this.selectedSubTypes.length > 0;

    if (symbolValue) {
      this.queryParams.symbol = symbolValue.id;
    }

    if (contractMonthYear) {
      this.queryParams.contractMonthYear = this.monthCode.translateMomentToContractMonth(contractMonthYear);
    }

    if (strikePriceMin) {
      this.queryParams.strikePriceMin = strikePriceMin;
    }

    if (strikePriceMax) {
      this.queryParams.strikePriceMax = strikePriceMax;
    }

    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow order dated on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = DEFAULT_END_DATE;
    }

    if (sideValue) {
      this.queryParams.side = sideValue;
    }

    if (hasAnySubType) {
      this.queryParams.securitySubTypes = this.selectedSubTypes.join(',');
    }

    if (securityTypes) {
      this.queryParams.securityTypes = securityTypes.join(',');
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });

    if (accounts.length === 0) {
      return of([]);
    }
    return combineLatest(
      accounts.map((account) => {
        const subTypeOrderSearch: OrderSearchCriteria = {
          accountDocId: account.docId,
          side: sideValue,
          symbol: symbolValue.electronicOptionsSymbol,
          contractYearMonth: contractMonthYear ? this.monthCode.translateMomentToContractMonth(contractMonthYear) : null,
          securitySubType: hasSecuritySubType ? this.selectedSubTypes[0] : null,
          maxStrikePrice: strikePriceMax,
          minStrikePrice: strikePriceMin
        };

        const securityTypeOrderSearch: OrderSearchCriteria = {
          accountDocId: account.docId,
          side: sideValue,
          symbol: symbolValue.electronicOptionsSymbol,
          contractYearMonth: contractMonthYear ? this.monthCode.translateMomentToContractMonth(contractMonthYear) : null,
          securityType: hasSecuritySubType ? securityTypes.filter(type => type !== 'UDS') : securityTypes,
          maxStrikePrice: strikePriceMax,
          minStrikePrice: strikePriceMin
        };

        const udsOrderSearch: OrderSearchCriteria = {
          accountDocId: account.docId,
          side: sideValue,
          symbol: symbolValue.electronicOptionsSymbol,
          contractYearMonth: contractMonthYear ? this.monthCode.translateMomentToContractMonth(contractMonthYear) : null,
          securityType: ['UDS'],
          maxStrikePrice: strikePriceMax,
          minStrikePrice: strikePriceMin
        };

        // if securityType includes UDS
        let udsQuery = of([]);
        if (this.hasUds && this.selectedSubTypes.length === 1) {
          udsQuery = this.orderService.getOrdersByAccountDocIdAndParameters(udsOrderSearch).pipe(
            map(orders => {
              return orders.filter(order => order.legs.some(val => val.securitySubType === this.selectedSubTypes[0]));
            }));
        }

        // if 1 or more securitySubTypes are selected
        let subTypeQuery = of([]);
        if (subTypeOrderSearch.securitySubType !== null) {
          subTypeQuery = this.orderService.getOrdersByAccountDocIdAndParameters(subTypeOrderSearch);
        }

        // if a securityType other than UDS is selected or if no Types and no subTypes are selected
        let typeQuery = of([]);
        if (securityTypes && securityTypes.filter(type => type !== 'UDS').length > 0
          || (!securityTypes || securityTypes.length === 0) && !hasSecuritySubType) {
          typeQuery = this.orderService.getOrdersByAccountDocIdAndParameters(securityTypeOrderSearch);
        }

        return combineLatest([subTypeQuery, typeQuery, udsQuery]).pipe(
          map(Orders => Orders.flat())
        );
      })
    ).pipe(map((orders) => orders.flat())).pipe(
      map(orders => {
        return orders.filter(order => new Date(order.lastUpdatedTimestamp).valueOf() > new Date(startDate).valueOf()
          && new Date(order.lastUpdatedTimestamp).valueOf() < new Date(endDate).valueOf());
      }));
  }

  private splitAccounts(accountValue: string): string[] {
    const accountNumbers = accountValue.split(',').map((accountNum) => accountNum.trim());
    // filter duplicate account Numbers
    const accountNumbersFiltered = [...new Set(accountNumbers)];
    this.filteredBy = `Account Number - ${accountNumbersFiltered.join(', ')}`;
    return accountNumbersFiltered;
  }

  private splitBrokerCodes(brokerCodes: string): string[] {
    const brokerCodeValues = brokerCodes.split(',').map((code) => code.trim());
    let brokerCodesFiltered = [];

    // filter duplicate brokerCodes
    brokerCodesFiltered = [...new Set(brokerCodeValues)];
    this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    return brokerCodesFiltered;
  }

  // Clears the value and disables client field and enables account field
  accountFieldClicked() {
    this.orderSearchForm.get('brokerCode').setValue('');
    this.orderSearchForm.get('brokerCode').disable();
    this.orderSearchForm.get('account').enable();
  }

  // Clears the value and disables account field and enables brokerCode field
  brokerCodeFieldClicked() {
    this.orderSearchForm.get('account').setValue('');
    this.orderSearchForm.get('account').disable();
    this.orderSearchForm.get('brokerCode').enable();
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom',
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom',
      });
    }
  }
  displayCommodity(commodity: Commodity): string {
    if (commodity) {
      return commodity.name;
    }
    return '';
  }

  clearSide() {
    this.orderSearchForm.get('side').setValue('');
  }
}
