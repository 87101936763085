<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="rders-filter-field" #filter>
      <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="selectedOrders$ | async" aria-label="Export" id="export-orders" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Orders'))">Excel</button>
      <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Orders'))">CSV</button>
      <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Orders'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="lastUpdatedTimestamp" matSortDirection="desc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="8" fxFlex.md="9" fxFlex.gt-md="7">Account</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs ="33" fxFlex.sm="8" fxFlex.md="9" fxFlex.gt-md="7">{{ order.accountNumber }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9">Order #</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9">{{ order.docId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9">Created</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="33" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9"><span class="forced-wrap">{{ order.creationTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9">Updated</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="33" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9"><span class="forced-wrap">{{ order.lastUpdatedTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">Side</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">{{ order.side | uppercase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">Qty</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">{{ order.quantity }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="commodity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="9">Commodity</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="9">{{ getCommodityDisplayName(order.symbol) | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="future">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6">Future</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6">{{ order.contractYearMonth | codeToMonth: 'short' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="security">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="7">Security</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="8"><span class="forced-wrap">{{ order.security }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="strike">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="6">Strike</mat-header-cell>
        <mat-cell *matCellDef="let order"  fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="6"><span>{{ order.strikePrice }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="5" fxFlex.gt-md="6">Target</mat-header-cell>
        <mat-cell *matCellDef="let order"  fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="5" fxFlex.gt-md="6">
          <ng-container>{{ order.type === 'MARKET' ? 'Market' : (order.price | number:'1.4-4') }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="5" fxFlex.gt-md="4">Type</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">{{ order.type | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="subtype">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="5" fxFlex.gt-md="4">P/C</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">{{ order.subType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="timeInForce">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="5" fxFlex.gt-md="4">TIF</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">{{ order.timeInForce | uppercase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="6">Status</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="6">{{ order.status | replace | uppercase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="strategy">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="14" fxFlex.md="8" fxFlex.gt-md="6">Strategy</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.sm="14" fxFlex.md="8" fxFlex.gt-md="6">{{ order.spreadStrategy }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectOrder(order)" *matRowDef="let order; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>

  </div>
</div>

