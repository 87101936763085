<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Trades List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Trades</h2>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
    <form [formGroup]="transactionsForm" (ngSubmit)="searchTransactions(true)">
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="25px">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="accountFieldClicked()">
            <input matInput placeholder="Account" formControlName="account">
            <mat-error *ngIf="transactionsForm.get('account').invalid">{{formValidator.getErrorMessages(transactionsForm.get('account'))}}</mat-error>
          </mat-form-field>
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="clientFieldClicked()">
          <input matInput placeholder="Broker Code" formControlName="brokerCode">
          <mat-error *ngIf="transactionsForm.get('brokerCode').invalid">{{formValidator.getErrorMessages(transactionsForm.get('brokerCode'))}}</mat-error>
        </mat-form-field>
      </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field class="example-full-width" fxFlex.gt-xs="25" fxFlex.xs="100">
            <mat-label>Commodity</mat-label>
            <input type="text"
                   placeholder="Commodity"
                   aria-label="Commodity"
                   matInput
                   formControlName="symbol"
                   [matAutocomplete]="auto"
                   trim="blur">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCommodity">
              <mat-option *ngFor="let commodity of filteredCommodities | async" [value]="commodity">
                {{displayCommodity(commodity)}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex="45">
              <input matInput placeholder="Strike (min)" formControlName="minStrike">
              <mat-error *ngIf="transactionsForm.get('minStrike').invalid">{{formValidator.getErrorMessages(transactionsForm.get('minStrike'))}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="45">
              <input matInput placeholder="Strike (max)" formControlName="maxStrike">
              <mat-error *ngIf="transactionsForm.get('maxStrike').invalid">{{formValidator.getErrorMessages(transactionsForm.get('maxStrike'))}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field arcMonthYearPicker fxFlex.gt-xs="25" fxFlex.xs="100">
           <input matInput [matDatepicker]="yearMonthPicker" placeholder="Month/Year" formControlName="contractMonthYear">
           <mat-datepicker-toggle matSuffix [for]="yearMonthPicker"></mat-datepicker-toggle>
           <mat-datepicker #yearMonthPicker startView="year" (monthSelected)="selectYearMonth($event)" panelClass="month-year-picker"></mat-datepicker>
          </mat-form-field>
          <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex="45">
              <input matInput [matDatepicker]="startDatePicker" placeholder="Trade Date (Start)" formControlName="startTradeDate" [max]="transactionsForm.get('endTradeDate').value">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="transactionsForm.get('startTradeDate').invalid">{{ error }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="45">
              <input matInput [matDatepicker]="endDatePicker" placeholder="Trade Date (End)" formControlName="endTradeDate" [min]="transactionsForm.get('startTradeDate').value">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="transactionsForm.get('endTradeDate').invalid">{{ error }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100">
        <div class="checkbox-group" fxFlex.xs="100" fxFlex.gt-xs="50">
          <mat-checkbox #subTypeBoxes *ngFor="let type of tradeSubTypes" (change)="onSubTypeChange($event)" [value]="type" labelPosition="before">
            {{ type | replace | titlecase }}
          </mat-checkbox>
          <mat-checkbox #securityBox *ngFor="let future of ['FUTURE']" (change)="onTypeChange($event)" [value]="future" labelPosition="before">
            Future
          </mat-checkbox>
        </div>
        <mat-radio-group formControlName="side" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px"  fxLayoutGap.xs="10px" fxLayoutAlign.gt-xs="start center">
          <mat-radio-button  *ngFor="let side of tradeSides" [value]="side" labelPosition="before">
            {{ side | replace | titlecase }}
          </mat-radio-button>
          <button id='clear-side' mat-button *ngIf="transactionsForm.get('side').value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSide()" matTooltip="Clear Side">
            <mat-icon>close</mat-icon>
          </button>
        </mat-radio-group>
      </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <button mat-raised-button [disabled]="!(transactionsForm.get('account').value || transactionsForm.get('brokerCode').value) || !transactionsForm.valid" color="accent" type="submit">Search</button>
          <button id="clear-trade-search" *ngIf="!transactionsForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
      </div>
    </form>
    <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
      <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
    </div>
    <div *ngIf="showData && !errorMessage" class="trade-list">
      <arc-trades (transactionListChange)="handleTransactionListChange($event)" (transactionSearchError)="handleTransactionListError($event)" (isSearching)="handleIsSearching($event)" [selectedTransactions$]="selectedTransactions$" [initialTableState]="tableState"></arc-trades>
    </div>
    </div>
  </mat-card>
</div>
