<div fxFlex="100">
  <div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="transactions-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable && selectedTransactions$ | async" aria-label="Export" id="export-transactions" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Trades'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Trades'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Trades'))">JSON</button>
      </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-xs="7">Account</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="33" fxFlex.gt-xs="7">{{ transaction.accountNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tradeDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-xs="7">Trade Date </mat-header-cell>
          <mat-cell *matCellDef="let transaction" fxFlex.xs="33" fxFlex.gt-xs="7">{{ transaction.tradeDate | date: 'shortDate' }}</mat-cell>
          </ng-container>
      <ng-container matColumnDef="contractMonthYear">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="7">Month/Year</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="25" fxFlex.gt-xs="7">{{ transaction.contractYearMonth | codeToMonth: 'long' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="6">Side</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="25" fxFlex.gt-xs="6">{{ transaction.side | titlecase }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="commission">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="10">Commission</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="10">{{ transaction.commission }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="securitySubType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="6">Put/Call</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="6">{{ transaction.securitySubType }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="clearingFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="10">Clearing Fee </mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="10">{{ transaction.clearingFee}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="nfaFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="7">NFA Fee </mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="7">{{ transaction.nfaFee }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="exchangeFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="10">Exchange Fee</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="10">{{ transaction.exchangeFee }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="pitFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="7">Pit Fee</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="7">{{ transaction.pitFee }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="brokerCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="7">Broker Code</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="7">{{ transaction.officeCode + transaction.salesCode}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="4">Qty</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="25" fxFlex.gt-xs="4">{{ transaction.quantity}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="commodity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-xs="7">Commodity</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="33" fxFlex.gt-xs="7">{{ getCommodityDisplayName(transaction.commodityId) }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="strike">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="5">Strike</mat-header-cell>
        <mat-cell *matCellDef="let transaction" fxFlex.xs="30" fxFlex.gt-xs="5">{{ transaction.strikePrice}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row  *matRowDef="let transaction; columns: columnsToDisplay" (click)="selectTrade(transaction)"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>
  </div>
  </div>
</div>
