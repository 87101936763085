<div class="root-container" fxFlexFill>
  <mat-toolbar class="toolbar" fxLayout="row">
    <button mat-icon-button (click)="sidenav.toggle()" class="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/ATI_Lockup_RGB_S_green.png" alt="ATI Logo">
    <span class="fill-remaining-space"></span>
    <span>
      <button id="profile-menu-btn" mat-button [matMenuTriggerFor]="profile"><img class="profile-picture"
          [src]="authService?.userProfile?.picture || 'assets/nopicture.png'"></button>
      <mat-menu id="profile-menu" #profile="matMenu">
        <ng-container *ngIf="(loggedInUser$ | async) as user">
          <h4 id="welcome-item">{{user.firstName}} {{user.lastName}}</h4>
          <mat-divider></mat-divider>
          <a mat-menu-item id="profile-item" (click)="navigateToProfile(user.docId)">Profile</a>
        </ng-container>
        <ng-container>
          <a mat-menu-item id="login-item" (click)="authService.login()" *ngIf="!authService?.isLoggedIn">Login</a>
          <a mat-menu-item id="logout-item" (click)="authService.logout()" *ngIf="authService?.isLoggedIn">Logout</a>
        </ng-container>
      </mat-menu>
    </span>
  </mat-toolbar>
  <mat-sidenav-container class="nav-container">
    <mat-sidenav #sidenav fxLayout="column" [(opened)]="sideNavOpened" [mode]="sideNavMode" class="nav-menu"
      fxFlex.xs="45vw" fxFlex.sm="35vw" fxFlex.md="25vw" fxFlex.lg="15vw" fxFlex.gt-lg="15vw">
      <mat-nav-list>
        <mat-list-item routerLinkActive="active" *ngIf="authService?.userProfile" class="nav-menu-item">
          <mat-icon color="primary" mat-list-icon>home</mat-icon>
          <a routerLink="/home" matLine (click)="handleSideNavigation()">Home</a>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item routerLinkActive="active" *ngIf="authService?.userProfile" class="nav-menu-item">
          <mat-icon color="primary" mat-list-icon>receipt</mat-icon>
          <a routerLink="/orders" matLine (click)="handleSideNavigation()">Orders</a>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item routerLinkActive="active" *ngIf="authService?.userProfile" class="nav-menu-item">
          <mat-icon color="primary" mat-list-icon>timeline</mat-icon>
          <a routerLink="/positions" matLine (click)="handleSideNavigation()">Positions</a>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item routerLinkActive="active" *ngIf="authService?.userProfile" class="nav-menu-item">
          <mat-icon color="primary" mat-list-icon>swap_horiz</mat-icon>
          <a routerLink="/trades" matLine (click)="handleSideNavigation()">Trades</a>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" class="nav-content" fxFlex="grow">
      <router-outlet *ngIf="!errorMessage && (authGuard.loading$ | async) === false"></router-outlet>
      <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90"
        *ngIf="!errorMessage && authGuard.loading$ | async">
        <mat-spinner diameter="60" color="accent"></mat-spinner>
        <p>Loading...</p>
      </div>
      <div fxLayoutAlign="space-around start" fxLayout="row" *ngIf="errorMessage">
        <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="65">
          <div>
            <h2 class="card-title">Error Loading Golden Dragon</h2>
            <p [innerHTML]="errorMessage"></p>
            <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
              or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
          </div>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
