<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Orders Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>

      <form *ngIf="!errorMessage" [formGroup]="orderSearchForm" (ngSubmit)="searchOrders(true)" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">Orders</h2>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100" (click)="accountFieldClicked()">
            <mat-label>Account Number</mat-label>
            <mat-error *ngIf="orderSearchForm.get('account').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('account'))}}</mat-error>
            <input matInput formControlName="account" trim="blur">
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100" (click)="brokerCodeFieldClicked()">
            <mat-label>Broker Code</mat-label>
            <input matInput formControlName="brokerCode" trim="blur">
            <mat-error *ngIf="orderSearchForm.get('brokerCode').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('brokerCode'))}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field class="example-full-width" fxFlex.gt-xs="22" fxFlex.xs="100">
            <mat-label>Commodity</mat-label>
            <input type="text"
                   placeholder="Commodity"
                   aria-label="Commodity"
                   matInput
                   formControlName="symbol"
                   [matAutocomplete]="auto"
                   trim="blur">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCommodity">
              <mat-option *ngFor="let commodity of filteredCommodities | async" [value]="commodity">
                {{displayCommodity(commodity)}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
          <mat-form-field fxFlex="45">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Updated Date (Start)" formControlName="startDate" [max]="orderSearchForm.get('endDate').value">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="orderSearchForm.get('startDate').invalid">{{ getErrorMessage(orderSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="45">
            <input matInput [matDatepicker]="endDatePicker" placeholder="Updated Date (End)" formControlName="endDate" [min]="orderSearchForm.get('startDate').value">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="orderSearchForm.get('endDate').invalid">{{ getErrorMessage(orderSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field arcMonthYearPicker fxFlex.gt-xs="22" fxFlex.xs="100" >
            <input matInput [matDatepicker]="orderYearMonthPicker" placeholder="Month/Year"
              formControlName="contractMonthYear">
            <mat-datepicker-toggle matSuffix [for]="orderYearMonthPicker"></mat-datepicker-toggle>
            <mat-datepicker #orderYearMonthPicker startView="year" (monthSelected)="selectMonthYear($event)"
              panelClass="month-year-picker"></mat-datepicker>
          </mat-form-field>

          <div fxFlex.gt-xs="45" fxFlex.xs="100" fxFlex="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex="45">
              <input matInput placeholder="Strike (min)" formControlName="strikePriceMin" maxlength="10">
              <mat-error *ngIf="orderSearchForm.get('strikePriceMin').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('strikePriceMin'))}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="45">
              <input matInput placeholder="Strike (max)" formControlName="strikePriceMax" maxlength="10">
              <mat-error *ngIf="orderSearchForm.get('strikePriceMax').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('strikePriceMax'))}}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <div class="checkbox-group">
          <mat-checkbox #subTypeBoxes *ngFor="let type of orderSubTypes" (change)="onSubTypeChange($event)" [value]="type" labelPosition="before">
            {{ type | replace | titlecase }}
          </mat-checkbox>
          <mat-checkbox #securityBox *ngFor="let future of ['FUTURE']" (change)="onTypeChange($event)" [value]="future" labelPosition="before">
            Future
          </mat-checkbox>
        </div>

        <mat-radio-group formControlName="side" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
          <mat-radio-button  *ngFor="let side of orderSides" [value]="side" labelPosition="before">
            {{ side | replace | titlecase }}
          </mat-radio-button>
          <button id='clear-side' mat-button *ngIf="orderSearchForm.get('side').value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSide()" matTooltip="Clear Side">
            <mat-icon>close</mat-icon>
          </button>
        </mat-radio-group>
      </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-order-search" [disabled]="!(orderSearchForm.get('account').value || orderSearchForm.get('brokerCode').value) || !orderSearchForm.valid" aria-label="Search" type="submit"
            mat-raised-button color="accent">Search</button>
          <button id="clear-order-search" *ngIf="!orderSearchForm.pristine" aria-label="Reset" (click)="reset()"
            mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>
      </form>

      <!-- Display Orders list screen below OrderSearch -->
      <div *ngIf="showOrders && !errorMessage" class="order-list">
        <arc-orders (orderListChange)="handleOrderListChange($event)" (orderSearchError)="handleOrderListError($event)" (isSearching)="handleIsSearching($event)"
          [selectedOrders$]="selectedOrders$" [initialTableState]="tableState"></arc-orders>
      </div>
    </mat-card>
  </div>

</div>
