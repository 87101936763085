import { Component, Input, OnInit } from '@angular/core';

import { CommodityMap, OrderLeg } from '@advance-trading/ops-data-lib';

const PRICE_DIVISOR = 100;

@Component({
  selector: 'arc-order-leg',
  templateUrl: './order-leg.component.html',
  styleUrls: ['./order-leg.component.css']
})
export class OrderLegComponent implements OnInit {

  @Input() leg: OrderLeg;
  @Input() commodityMap: CommodityMap;

  constructor() { }

  ngOnInit() {
  }

  getDisplayPrice(price: number) {
    return price / PRICE_DIVISOR;
  }

}

