// Angular
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatIconRegistry
} from '@angular/material';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// External libraries
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatTableExporterModule } from 'mat-table-exporter';

// Internal libraries
import { AtiSecurityModule } from '@advance-trading/angular-ati-security';
import { CommonServicesModule } from '@advance-trading/angular-common-services';

// Local
import { environment } from '../environments/environment';
import { MonthYearPickerDirective } from './utilities/month-year-picker.directive';

// Components
import { UserStatusService } from './auth/user-status.service';
import { HomeComponent } from './home/home.component';
import { CallbackComponent } from './callback/callback.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderSearchComponent } from './orders/order-search/order-search.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { OrderLegComponent } from './orders/order-leg/order-leg.component';
import { OrderSecurityPipe } from './utilities/order-security-pipe';
import { PositionsComponent } from './positions/positions.component';
import { PositionSearchComponent } from './positions/position-search/position-search.component';
import { PositionDetailComponent } from './positions/position-detail/position-detail.component';
import { TradesComponent } from './trades/trades.component';
import { TradeSearchComponent } from './trades/trade-search/trade-search.component';
import { TradeDetailComponent } from './trades/trade-detail/trade-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CallbackComponent,
    MonthYearPickerDirective,
    OrdersComponent,
    OrderDetailComponent,
    OrderLegComponent,
    OrderSearchComponent,
    OrderSecurityPipe,
    PositionsComponent,
    PositionSearchComponent,
    PositionDetailComponent,
    TradesComponent,
    TradeSearchComponent,
    TradeDetailComponent,
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AppRoutingModule,
    AtiSecurityModule.configure(environment),
    BrowserAnimationsModule,
    BrowserModule,
    CommonServicesModule.configure(environment),
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTableExporterModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    NgxMaterialTimepickerModule,
    NgxMatIntlTelInputModule,
    NgxTrimDirectiveModule,
    NgxUpperCaseDirectiveModule,
    ReactiveFormsModule
  ],
  providers: [
    UserStatusService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true }
    },
    {
    provide: 'Window',
    useValue: window
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
