import { Component, OnInit } from '@angular/core';
import { CommodityMap, Transaction} from '@advance-trading/ops-data-lib';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { TransactionService } from '@advance-trading/angular-ops-data';
import { catchError, switchMap, tap } from 'rxjs/operators';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';

@Component({
  selector: 'arc-trade-detail',
  templateUrl: './trade-detail.component.html',
  styleUrls: ['./trade-detail.component.css']
})
export class TradeDetailComponent implements OnInit {
  isLoading = false;
  errorMessage: string;

  transaction$: Observable<Transaction>;

  transactionDocId: string;
  commodityMap: CommodityMap;
  transactionDescription: string;
  private transactionAccountDocId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private transactionService: TransactionService,
  ) { }

  ngOnInit() {

    if (!this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to view Trades.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.isLoading = true;
    this.transaction$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        this.transactionAccountDocId = paramMap.get('accountDocId');
        this.transactionDocId = paramMap.get('transactionDocId');
        return this.transactionService.getTransactionByDocId(this.transactionAccountDocId, this.transactionDocId);
      }),
      tap(transaction => {
        this.transactionDescription = transaction.securityDescription;
        this.isLoading = false;
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving trade data; please try again later';
        console.error(`Error retrieving trade data: ${err}`);
        return of(undefined);
      })
    );
  }

}

