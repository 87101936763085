// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

// External libraries
import { Observable, of } from 'rxjs';
import { User } from '@advance-trading/ops-data-lib';
import { catchError, switchMap, tap } from 'rxjs/operators';


// Internal libraries
import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { UserService } from '@advance-trading/angular-ops-data';


// Local
import { AuthGuard } from './auth/auth.guard';


const ALL_CLIENT_VIEWER_ROLE = 'AllClientViewer';


@Component({
  selector: 'arc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [BreakpointObserver]

})
export class AppComponent implements OnInit {
  title = 'Advance Trading Risk Management System - Golden Dragon';
  sideNavOpened: boolean;

  sideNavMode = 'over';
  loggedInUser$: Observable<User>;

  isRegistering = false;

  tooltip = 'New...';
  errorMessage: string;
  isXSmall = false;

  private loggedInUser: User;

  constructor(
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    @Inject('environment') private environment,
    private router: Router,
    private userService: UserService,
    public authGuard: AuthGuard,
    public authService: AuthService,
  ) {
    this.authGuard.authErrors$.subscribe(error => {
      // TODO is this always returning going to cover up other errors? Perhaps we don't send '' back
      this.errorMessage = error;
    });

    this.authService.localAuthSetup().pipe(
      catchError(err => {
        // Check for a Firebase login problem associated with email verification
        if (err.error && err.error.message.includes('not verified')) {
          // Show not verified email screen
          console.log('Your email is NOT yet verified');
          this.errorMessage = `Your email address is not yet verified. ` +
            `Please follow the instructions contained in the verification email and then login again.`;
        } else {
          console.error(`Error occurred in authService.localAuthSetup: ${err}`);
          this.errorMessage = 'An unknown login error has occurred. Please try again later.';
        }
        return of(undefined);
      })
    ).subscribe();
  }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XLarge] || state.breakpoints[Breakpoints.Large]) {
          this.sideNavMode = 'side';
          this.sideNavOpened = true;
        } else {
          this.sideNavMode = 'over';
          this.sideNavOpened = false;
        }

        // Set an indicator of XSmall screen size to assist in customizing toolbar
        this.isXSmall = state.breakpoints[Breakpoints.XSmall];

        this.loggedInUser$ = this.authService.userProfile$.pipe(
          switchMap(userProfile => {
            // Avoid an error when a new user is registered but their Auth0 token doesn't yet have a firestoreDocId
            if (userProfile.app_metadata['firestoreDocId']) {
              return this.userService.getUserByDocId(userProfile.app_metadata['firestoreDocId']);
            } else {
              return this.userService.getUserByAuthId(userProfile.sub);
            }
          }),
          tap(user => this.loggedInUser = user),
          catchError(err => {
            console.error(`Could not retrieve User by firestoreDocId or Auth0 authId: ${err}`);
            return of(undefined);
          })
        );
      });
  }

  handleSideNavigation() {
    if (this.sideNavMode !== 'side') {
      this.sideNavOpened = !this.sideNavOpened;
    }
  }
  navigateToProfile() {
    this.router.navigate(['/settings/users', this.loggedInUser.docId]);
  }

  canViewOrders() {
    return this.authzService.currentUserHasRole(ALL_CLIENT_VIEWER_ROLE );
  }

}
