import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { UserService } from '@advance-trading/angular-ops-data';
import { Status } from '@advance-trading/ops-data-lib';

@Injectable()
export class UserStatusService {

  constructor(
    private router: Router,
    private userService: UserService,
    @Inject('Window') private window: Window
  ) {}

  /**
   * A reusable function that checks the logged in User.status and User.prosperEnabled and returns an Observable<string>
   * if there is an error message to tell the user. Should be invoked anytime a route is changed or a user logs in.
   *
   * @param userProfile The userProfile from AuthService.userProfile$
   * @param routeToPrimary An optional indicator of whether we want to route an active user to /home; typically only
   * used when logging in with userId and password (e.g. CallbackComponent). In other scenarios like normal navigation,
   * we don't want to route the user to /home.
   */
  checkActiveUser(userProfile, routeToPrimary?: boolean): Observable<string> {

    return this.userService.getUserByDocId(userProfile.app_metadata['firestoreDocId']).pipe(
      shareReplay({bufferSize: 1, refCount: true}),
      switchMap(user => {
        // Check registration status and handle appropriately
        if (user.status === Status.ACTIVE) {
          // Check that the user is prosperEnabled TODO: Using prosper for now until we get a name for GD!
          if (!user.prosperEnabled) {
            console.log('User not enabled for Golden Dragon access');
            return of(`Golden Dragon is not enabled for ${user.email}. Contact your administrator.`);
          } else if (routeToPrimary) {
            // Clear any prior errors
            const originUri = this.window.sessionStorage.getItem('originUri');
            if (originUri) {
              this.router.navigateByUrl(originUri);
              // Clear out the sessionStorage originUri after routing
              this.window.sessionStorage.setItem('originUri', '');
            } else {
              this.router.navigate(['/home']);
            }
          }
          return of('');
        } else {
          console.log('Status is undefined');
          return of(`The status of this account is unknown. You are not able to login.`);
        }
      })
    );
  }

}
