import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor() {}

  getExportOptions(type: string, reportName: string) {
    const currentDateTime = moment().format('YYYY-MM-DD hh:mm:ss A');

    switch (type) {
      case 'xlsx':
        return {
          fileName: `ARC-${reportName}-${currentDateTime}`,
          sheet: reportName,
          Props: {
            Author: 'Advance Trading, Inc.'
          }
        };
      default:
        return {
          fileName: `ARC-${reportName}-${currentDateTime}`
        };
    }
  }

}
