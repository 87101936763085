<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="85">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Position Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Position: {{ positionDocId ? positionDocId : '' }}</h2>
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
    </div>

    <div *ngIf="!errorMessage && position$ | async as position" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Client Name" [data]="position.clientName">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Exchange Id" [data]="position.exchangeId">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="FCM" [data]="position.fcm">
        </ati-labeled-data>

      </div>
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Contract Month Year" [data]="position.contractYearMonth | codeToMonth: 'long'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Security Description" [data]="position.securityDescription">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="34" fxFlex.xs="100" label="Account Number" [data]="position.accountNumber">
        </ati-labeled-data>
      </div>
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Side" [data]="position.side | titlecase">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Total Quantity" [data]="position.totalQuantity">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Strike Price" [data]="position.strikePrice ? position.strikePrice.toFixed(2) : '-'">
        </ati-labeled-data>
      </div>

      <ng-container *ngIf="position.segments && position.segments.length > 0">
        <h3 fxFlex="100">Segments</h3>
        <div fxLayout="column" fxLayoutGap.gt-xs="15px" class="position-segments">
        <div *ngFor="let segment of position.segments; last as last" fxFlex="100">
          <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start">
            <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Quantity" [data]="segment.quantity">
            </ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Trade Price" [data]="segment.tradePrice">
            </ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Trade Date" [data]="segment.tradeDate">
            </ati-labeled-data>
            <mat-divider inset *ngIf="!last"></mat-divider>
          </div>
        </div>
        </div>
      </ng-container>
    </div>
  </mat-card>
</div>
