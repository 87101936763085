import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export class OrderSearchFormValidator {

  constructor() { }

  AccountsAndBrokerCodesValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('([a-zA-Z0-9]*[,]\\s?){0,9}([a-zA-Z0-9]*){1}');
    };
  }

  strikeValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('[1-9][0-9]*[.]?[0-9]*');
    };
  }

  getErrorMessages(control: FormControl) {

    if (control === control.parent.get('account') && control.hasError('pattern')) {
      return 'Invalid Account Numbers';
    } else if (control === control.parent.get('brokerCode') && control.hasError('pattern')) {
      return 'Invalid Sales Codes';
    } else if ((control === control.parent.get('strikePriceMin') || control === control.parent.get('strikePriceMax'))
        && control.hasError('pattern')) {
      return 'Invalid Strike Value';
    } else {
      return 'Invalid Value';
    }
  }
}
