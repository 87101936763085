import { AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges } from '@angular/core';
import { CommodityMap, Transaction } from '@advance-trading/ops-data-lib';
import { Observable, of } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { MatPaginator, MatSort, MatSnackBar, SortDirection } from '@angular/material';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { ExportService } from '../Services/export.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'arc-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.css']
})
export class TradesComponent implements AfterViewInit, OnChanges, OnInit {

  @Input() initialTableState: {[key: string]: string|number};
  @Input() selectedTransactions$: Observable<Transaction[]>;

  columnsToDisplay = [];
  dataSource = new ObservableDataSource<Transaction>();
  errorMessage: string;
  commodityMap: CommodityMap;
  exportable = false;
  filterValue = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() transactionListChange: EventEmitter<any> = new EventEmitter();
  @Output() transactionSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  private tableState: {[key: string]: string|number} = {};

  constructor(
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private operationsDataService: OperationsDataService,
    private snackBar: MatSnackBar,
    public exportService: ExportService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);

    this.filterValue.valueChanges.subscribe((filter: string) => {
      if (filter) {
        this.tableState.filterValue = filter.trim();
        this.transactionListChange.emit(this.tableState);
      } else if (this.tableState.filterValue) {
        delete this.tableState.filterValue;
        this.transactionListChange.emit(this.tableState);
      }
    });
    this.breakpointObserver.observe([Breakpoints.XSmall])
    .subscribe(state => {
      if (state.matches) {
        this.columnsToDisplay = [
          'accountNumber', 'commodity', 'tradeDate'
        ];
      } else {
        this.columnsToDisplay = [
          'accountNumber', 'brokerCode', 'commodity', 'tradeDate', 'contractMonthYear', 'side', 'quantity', 'strike', 'nfaFee', 'pitFee', 'exchangeFee', 'commission',  'clearingFee', 'securitySubType',
        ];
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedTransactions$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }
      if (this.initialTableState.filterValue) {
        this.filterValue.setValue(this.initialTableState.filterValue);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ = this.operationsDataService.getCommodityMap().pipe(
        switchMap((doc: CommodityMap) => {
          this.commodityMap = doc;
          return this.selectedTransactions$;
        })
      ).pipe(
        tap((transactions => {
          this.exportable = !!transactions.length;
          this.isSearching.emit(false);

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          const pageSize = this.initialTableState.pageSize as number;

          if (pageIndex !== undefined) {
            this.paginator.pageIndex = pageIndex;
          }
          if (pageSize !== undefined) {
            this.paginator.pageSize = pageSize;
          }

          if (transactions.length === 0) {
            this.openSnackBar('There are no trades that match this search', 'Dismiss', false);
          } else {
            catchError(err => {
              this.errorMessage = 'Error retrieving transactions; please try again later';
              this.transactionSearchError.emit(this.errorMessage);
              this.isSearching.emit(false);
              console.error(`Error retrieving transactions: ${err}`);
              return of([]);
            });
          }
        }))
      ).pipe(map(transactions => transactions.sort((a, b) => new Date(b.tradeDate).getTime() - new Date(a.tradeDate).getTime())));
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  getCommodityDisplayName(symbol: string) {
    if (!symbol) {
      return '';
    }
    const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.id === symbol);
    if (commodity) {
      return commodity.name;
    } else {
      return '';
    }
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.transactionListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.tableState.pageSize = this.paginator.pageSize;
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.transactionListChange.emit(this.tableState);
  }
  selectTrade(transaction: Transaction) {
    this.router.navigate(['/accounts', transaction.accountDocId, 'trades', transaction.docId]);
  }
}
