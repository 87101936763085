import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { CallbackComponent } from './callback/callback.component';
import { OrderSearchComponent } from './orders/order-search/order-search.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { PositionSearchComponent } from './positions/position-search/position-search.component';
import { PositionDetailComponent } from './positions/position-detail/position-detail.component';
import { TradeSearchComponent } from './trades/trade-search/trade-search.component';
import { TradeDetailComponent } from './trades/trade-detail/trade-detail.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'accounts/:accountDocId/orders/:orderDocId',
    component: OrderDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accounts/:accountDocId/positions/:positionDocId',
    component: PositionDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accounts/:accountDocId/trades/:transactionDocId',
    component: TradeDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    component: OrderSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'positions',
    component: PositionSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'trades',
    component: TradeSearchComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
